import styled from 'styled-components';

export const HeaderContainer = styled.button`
  width: calc(100% - 0px);
  display: flex;
  padding: 30px 15px;
  border-top: 1px solid ${(props) => props.theme.keyline};
  border-bottom: 1px solid ${(props) => props.theme.keyline};
  align-items: center;
  margin: 0 0 -1px;
`;

export const HeaderText = styled.p`
  display: flex;
  flex: 1;
  font-size: 20px;
  color: ${(props) => props.theme.textDark};
  font-weight: 600;
`;
